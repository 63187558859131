import React from "react";
import { Link } from "gatsby";
import { createReference } from "../utils/tilesUtil";
import OptimizedImage from "../../../utils/optimizedImage";
import { TextWrap, TileHeading, TileIcon, TileSubheading, TileWrap, NewTab } from "../styles/UseCaseTile.styled";
import { ConditionalWrapper } from "../../../atoms/ConditionalWrapper";

const UseCaseTile = ({ tile, isNew }) => {
  const {
    heading,
    description,
    subheading,
    icon,
  } = tile

  const reference = createReference(tile)
  const link = reference?.ctaButton?.link
  const tileDescription = description?.description || subheading

  return (
    <ConditionalWrapper
      condition={link}
      wrapper={children => <Link tabIndex={0} style={{ display: 'block', padding: '0px', textDecoration: 'none' }} to={link}>{children}</Link>}
    >
      {isNew &&
        <NewTab>
          New
        </NewTab>
      }
      <TileWrap isNew={isNew}>
        <TileIcon>
          <OptimizedImage image={icon?.gatsbyImageData} src={icon?.file?.url} alt={heading} />
        </TileIcon>
        <TextWrap>
          {heading && <TileHeading>{heading}</TileHeading>}
          {tileDescription && <TileSubheading>{tileDescription}</TileSubheading>}
        </TextWrap>
      </TileWrap>
    </ConditionalWrapper>
  )
}

export default UseCaseTile