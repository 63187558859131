import React from "react";
import OptimizedImage from "../../../utils/optimizedImage";
import { PlayButton } from "./Buttons";
import { PlayButtonWrap, VideoImageWrap } from "../styles/Tile.styled";

export const TileImageWrap = ({ icon, videoLink }) => (
  <VideoImageWrap videoLink={videoLink}>
    {videoLink &&
      <PlayButtonWrap>
        <span className="playicon">
          <PlayButton />
        </span>
      </PlayButtonWrap>
    }
    <OptimizedImage className="tile-image" image={icon?.gatsbyImageData} src={icon?.file?.url} alt="" />
  </VideoImageWrap>
)
